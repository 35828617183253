<template>
	<div>
		<search id="search-main" class="d-md-block d-none"></search>
		<div :class="{'search-mobile-bg-en' : lang === 'en', 'search-mobile-bg-pt' : lang === 'pt'}"></div>
		<div class="pb-5 main-bg">
			<search id="search-main" class="d-md-none d-block"></search>
			<div class="container pt-4 pb-4">
				<div class="row">
					<div class="col-12 text-center pb-4">
						<h2>{{ $t('message.mp_title1') }}</h2>
						<p class="subtitle">
							{{ $t('message.home_title1') }}
						</p>
					</div>
				</div>

				<div class="d-none d-md-block">
					<div class="row">
						<div class="col-12 col-md-4 pb-4" v-for="item in json.packs">
							<Card :list="item" :lang="lang"></Card>
						</div>
					</div>
				</div>

				<div class="d-block d-md-none">
					<carousel></carousel>
				</div>
			</div>
		</div>

		<div class="mp-dark-bg">
			<div class="container">
				<WhyZone></WhyZone>
			</div>
		</div>

		<div class="mp-yellow-bg">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="d-md-flex justify-content-md-between pb-3 pb-md-0">
							<div class="d-md-flex justify-content-md-start content">
								{{ $t('message.services.doubt') }}<span class="pl-2" style="color:white"> {{
									$t('message.services.contactus')
								}}!</span>
							</div>

							<div class="d-md-flex justify-content-md-end content">
								<a href="tel:+351 924 114 635"><i class="fas fa-phone mr-2"></i>+351 924 114 635</a>
								<router-link class="btn btn-primary mt-2 mt-md-0"
											 :to="{name:'contacts', params : {lang:lang}}">
									<i
										class="far fa-envelope"></i> {{ $t('message.services.contactus') }}
								</router-link>
							</div>
						</div>
						<p class="text-right" style="font-size:0.75rem;font-weight:400;margin: 15px;">{{ $t('message.cost_mobile') }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="mp-location-bg pt-5 pb-5">
			<Location></Location>
		</div>
	</div>
</template>

<script>

import json           from '../../website-data.json';
import Card           from '../components/Card';
import CustomCarousel from '../components/Carousel';
import Location       from '../components/Location';
import Search         from '../components/Search';
import WhyZone        from '../components/WhyZone';

export default {
	name       : 'MainPage',
	components : {
		'carousel' : CustomCarousel,
		Card,
		WhyZone,
		Location,
		'search'   : Search
	},
	data       : function () {
		return {
			json : json,
			lang : this.$route.params.lang
		};
	},
	methods    : {}
};
</script>
